@import "styles/variables.scss";

.brand {
  font-size: 20px;
  font-weight: 500;
}

.product-miniature {
  transition: transform 0.4s;
  width: 250px;
  height: 120px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
  // margin: 10px;

  -webkit-box-shadow: $primaryShadow;
  box-shadow: $primaryShadow;
  border-radius: 5px;

  &:hover {
    transform: translateY(-5px);
    border: 2px solid $brandAccent;
    padding: 8px;
  }
}

.product-miniature-top {
  display: flex;
  justify-content: space-between;
}

.product-miniature-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.state {
  width: fit-content;
  padding: 5px;
  border-radius: 4px;
  color: white;
  //   margin-top: 5px;
}

.brand {
  padding-left: 5px;
}

.delete {
  &:hover {
    color: $error;
  }
}

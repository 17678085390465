@import "styles/variables.scss";

.add-icon {
  width: 50px !important;
  height: 50px !important;
  background-color: transparent;
}

.add-operation-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  cursor: pointer;
  margin-bottom: 0;
}

.operation-entry {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 14px;
  background-image: linear-gradient(rgb(252, 252, 252), $shadeSecondary);
  border-radius: 3px;
  position: relative;
  -webkit-box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 12px;
  height: 156px;
  width: 468px;
  border: 1px solid $darkGray;

  &:hover {
    border: 2px solid $brandAccent;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .operation-entry {
    width: 100%;
  }
}

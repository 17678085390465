@import "styles/variables.scss";

.image-conversion {
  max-width: 900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.preview-image {
  width: 100px;
}

.file-card {
  display: flex;
  width: 100%;
}

.image-conversion {
  display: flex;
  flex-direction: column;
}

.upload-image {
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed $brandAccent;
  margin-top: 16px;
}

.upload-image-text {
  text-align: center;
  margin-top: 16px;
}

.upload-image-button {
  cursor: pointer;
  color: $brandAccent;
  text-decoration: underline;
}

.upload-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin: 10px 0;
}

.file-input {
  display: none;
}

.file-list {
  margin-top: 16px;
}

.file-card,
.file-info {
  width: 100%;
}

.in-drop-zone {
  background-color: $shadeAccentTransparency;
}

.info-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

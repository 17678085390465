@import "styles/variables.scss";

.draggable-product {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  width: 100%;
  display: flex;
  justify-content: center;
}

.product-miniature-container {
  padding: 10px;
  cursor: pointer;
  max-width: 265px;
  width: 100%;

  &:hover,
  &:active {
    background-color: $shadeAccent;
    border-color: $shadeAccent;
  }
}

@import "styles/variables.scss";

.logistic-action-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.message {
  margin-bottom: 10px;
}

.move-products {
  display: flex;
  margin-bottom: 10px;
  height: 100%;
}

.pending-list {
  border: none !important;
  padding: 5px 5px 15px 5px;
  width: 100%;
  max-width: 300px;
  background-color: transparent !important;
  max-height: unset !important;
}

.state-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 220px 220px;
  gap: 10px;
  width: 100%;
  // aspect-ratio: 1;
}

.validate-button {
  background-color: $success;
}

@media screen and (max-width: $mobileBreakpoint) {
  .move-products {
    flex-direction: column;
  }
}

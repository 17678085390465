@import "styles/variables.scss";

.operation-form {
  width: fit-content;
  height: fit-content;
  padding: 0 0 1rem 1rem;
}

@media screen and (max-width: $mobileBreakpoint) {
  .operation-form {
    width: 100%;
    padding: 0;
    margin-top: 1rem;
  }
}

@import "styles/variables.scss";

.discount-container {
  // padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.edit-button {
  cursor: pointer;
  background-color: unset;
  border: none;

  &:hover {
    color: $brandAccent;
  }
}

.shipping-price-value {
  padding-right: 5px;
}

.shipping-method-values {
  height: 48px;
}

.shipping-price-input {
  height: 48px;
  width: 80px !important;
  text-align: right;
  padding: 10px;
  min-width: unset;
}

.not-found-message {
  font-style: italic;
  text-align: center;
  opacity: 0.5;
}

.table-container {
  font-size: 1rem;
  width: 100%;

  table {
    th {
      text-align: left;
    }
    th:nth-child(2) {
      text-align: right;
    }
    td:nth-child(2) {
      text-align: right;
    }
    width: 100%;
  }
}

.title {
  font-size: 1rem;
  font-weight: bold;
}

.title-section {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: $mobileBreakpoint) {
  .shipping-price-input {
    width: 50px !important;
  }
}

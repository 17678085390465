@import "styles/variables.scss";

.address-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.button-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.chronopost {
  cursor: pointer;
}

.chronopost-list {
  margin-top: 20px;
}

.cancel-button {
  margin-bottom: 20px;
  margin-top: 10px;
  width: fit-content;
}

.invoice-frame {
  aspect-ratio: 1/1.41;
  max-height: 500px;
}

.outgoing-shipping-pro-body {
  display: flex;
}

.outgoing-shipping-pro-modal {
  -webkit-box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  border-radius: 4px;
  box-shadow: $primaryShadow;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  max-height: 800px;
  max-width: 1000px;
  overflow: auto;
  width: 90vw;
  padding: 20px;
}

.outgoing-shipping-pro-modal-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  // padding: 20px;
}

.title {
  font-size: 36px;
  margin-bottom: 20px;
}

.organization {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.pdf-viewer {
  // align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.selected {
  color: $brandAccent;
}

.shipping-info {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.shipping-info-item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

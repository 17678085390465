@import "styles/variables.scss";

.autocomplete-container {
  position: absolute;
  z-index: 2;
  height: 0;
  overflow: visible;
  top: -0.5rem;
  right: 0rem;
}

.autocomplete-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer !important;
  padding: 1rem;
}

.autocomplete-label {
  margin: 0 0.25rem 0 0;
  cursor: pointer !important;
  user-select: none;
}

.contact-widget {
  position: relative;
  padding: 2rem;
  border: 1px solid $darkGray;
  background-color: $shadeSecondary;
  border-radius: 4px;
  margin-right: 1rem;
  width: 100%;
}

@media screen and (max-width: $tabletBreakpoint) {
  .contact-widget {
    padding: 1rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .contact-widget {
    margin-right: 0;
  }
}

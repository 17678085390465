.add-section {
  width: 100%;
  display: flex;
}

.add-button {
  width: 100% !important;
  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.add-icon {
  margin-right: 1rem;
}

.disabled {
  cursor: default !important;

  &:hover {
    filter: unset;
  }
}

.description-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input {
  min-width: unset !important;
}

.input-container {
  position: relative;
  align-items: center;
  display: flex;
}

.open-list {
  position: absolute;
  right: 3px;
  top: 25px;
  height: 30px;
}

@import "styles/variables.scss";

.product-tab {
  width: 100%;
}

.product-tab-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media screen and (max-width: $mobileBreakpoint) {
  .product-tab-content {
    flex-direction: column;
  }
}

@import "styles/variables.scss";

.chevron {
  font-size: 30px;
}

.chevron-container {
  align-items: center;
  background-color: $brandAccent;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0px 20px;
  margin: 0 20px;
  height: 46px;
  width: 46px;

  &:hover {
    filter: brightness(90%);
  }
}

.close-button-container {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 0px;
  overflow: visible;
}

.close-icon {
  color: white;
  cursor: pointer;
  font-size: 30px !important;

  &:hover {
    color: $brandAccent;
  }
}

.disabled {
  filter: grayscale(100%);
  &:hover {
    // filter: unset;
    filter: grayscale(100%);
    cursor: default;
  }
}

.image-viewer {
  background-color: rgb(18, 18, 21, 0.9);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 2002;
}

.image-carrousel-container {
  width: 100%;
  border-top: 1px solid $brandAccent;
  background-color: $brandPrimary;
  overflow: auto;
  align-items: center;
  height: 101px;

  transition: opacity 0.2s ease-out;
  transition: opacity 1.2s ease-in;
}

.image-carrousel {
  width: fit-content;
  display: flex;
  padding: 10px;
}

.image-item {
  height: 80px !important;
  width: 80px !important;
  object-fit: scale-down;
  border-radius: 5px;
  &:not(:last-child) {
    margin-right: 5px;
  }
  transition: transform 0.5s ease-in-out;
}

.is-selected {
  border: 4px solid $brandAccent;
  border-radius: 5px;
  transform: scale(90%);
}

.hide {
  opacity: 0;
  // display: none;
}

.main-picture {
  height: 100% !important;
  width: fit-content;
  max-height: unset;
  max-width: 1600px;
  // cursor: zoom-in;
  cursor: zoom-in !important;

  transition: transform 1s ease-in-out;
  filter: brightness(100%) !important;
}

.main-picture-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.slider {
  display: flex;
  align-items: center;
  //   justify-content: space-between;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 101px);
}

.zoom-in {
  position: relative;
  cursor: zoom-out !important;
  transform: scale(200%);
}

@import "styles/variables.scss";

.read-one {
  display: flex;
}

.read-one-container {
  flex: 10;
  padding: 80px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tools {
  display: flex;
  flex-direction: column;
  gap: 20px;
  // padding: 20px;
  width: 0px;
  position: fixed;
  right: 60px;
  top: 80px;
}

.tools-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: $brandPrimary;
  color: $brandSecondary;
  border-radius: 50px;
  cursor: pointer;
}

.delete-icon {
  &:hover {
    color: $error;
  }
}

.background {
  background-color: $darkGray;
  position: fixed;
  z-index: 0;
  width: calc(100% - 300px);
  height: calc(100% - 50px);
  opacity: 0.4;
}

.modal {
  background-color: $brandSecondary;
  min-width: 550px;
  height: 720px;
  // max-height: 720px;
  padding: 40px 35px 20px 35px;
  border-radius: 5px;
  position: fixed;
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 1px 0px 20px 2px #888;
  -webkit-box-shadow: 1px 0px 20px 2px #888;
}

.close-button {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 5px;
  right: 5px;

  &:hover {
    color: $brandAccent;
  }
}

.nom-complet {
  display: flex;
  gap: 10px;
  font-weight: bold;
  font-size: 24px;
}

.access-button {
  display: flex;
  cursor: pointer;
}

.access-button-icon {
  height: 15px !important;
  width: 15px !important;
  color: $darkGray;
  &:hover {
    color: $brandAccent;
  }
}

.state {
  display: flex;
  justify-content: space-between;
  // justify-content: flex-end;
  gap: 10px;
  height: 50px;
}

.select-state {
  display: flex;
  align-items: center;
  gap: 50px;
}

.yellow,
.green,
.red {
  font-weight: bold;
}

.yellow {
  color: $brandAccent;
}

.green {
  color: $success;
}

.red {
  color: $error;
}

.edit-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  // flex: 2;
  order: 2;

  &:hover {
    color: $brandAccent;
  }
}

.modal-top {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}

.time-range {
  font-weight: bold;
  font-size: 40px;
}

.date {
  margin-bottom: 10px;
}

.direction-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  // flex: 4;
}

.external-shipping-action {
  display: flex;
  gap: 40px;
}

.external-shipping-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //   gap: 100px;
}

.external-shipping-logo {
  height: 15px;
  object-fit: scale-down;
}

.external-id {
  width: fit-content;
  padding: 10px;
  display: flex;
  justify-content: center;
  border: 1px solid $brandPrimary;
}

.download-container {
  display: flex;
  align-items: center;
  gap: 10px;
  // justify-content: center;
}

.download-button {
  cursor: pointer;
  background-color: $brandPrimary;
  order: 2;
  color: $brandAccent;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;

  &:hover {
    color: $brandPrimary;
    background-color: $brandAccent;
  }
}

.direction {
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  color: $brandSecondary;
  font-weight: bold;
}

.address {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address-icon {
  display: flex;
  align-items: center;
  // flex: 2;
}

.address-left {
  // flex: 3;
}

.outgoing {
  background-color: $info;
}

.incoming {
  background-color: $success;
}

.image-list {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  width: 100%;
  height: fit-content;
  // margin-top: 40px;
}

.product-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.product {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product-info,
.operation {
  display: flex;
  gap: 10px;
}

.product-info {
  font-weight: bold;
}

.image-list {
  display: flex;
  flex-wrap: wrap;
}

.image-preview {
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;

  &:hover {
    filter: brightness(95%);
  }
}

.done-button {
  background-color: $success;
  height: 30px;
  width: 30px;
  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &:hover {
    color: $brandPrimary;
  }
}

.bottom {
  display: flex;
  justify-content: right;
}

.address-comment {
  font-style: italic;
  font-size: 14px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

hr {
  margin: 0;
}

@media screen and (max-width: 1400px) {
  .container {
    width: 100%;
  }
  .background {
    width: 100%;
  }
}

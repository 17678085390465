@import "styles/variables.scss";

.timelimit {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.timelimit-container {
  margin-top: 10px;
  display: flex;
}

.timelimit-line {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.title {
  font-size: 1rem;
  font-weight: bold;
}

.input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
  -moz-appearance: textfield !important;
}

@media screen and (max-width: $mobileBreakpoint) {
  .timelimit-container {
    flex-direction: column;
  }

  .timelimit-line {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }
}

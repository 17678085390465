@import "styles/variables.scss";

.brand {
  font-size: 1rem;
}

.image-miniature {
  height: 70px !important;
  width: 70px !important;
  margin-right: 1rem;
  border: 1px solid $lightGray;
}

.product-item {
  display: flex;
  align-items: center;
}

.product-description {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
}

.operation-logo {
  height: 20px;
  margin-right: 0.5rem;
}

.state {
  font-size: 0.875rem;
  max-width: unset !important;
  width: fit-content;
  text-wrap: nowrap;
}

.product-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid $lightGray;
  }
  width: 100%;
}

.product-item-left {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  width: 100%;
}

.product-item-right {
  display: flex;
  width: fit-content;
  margin-left: auto;
  align-items: center;
}

.action-button {
  margin-left: 0.5rem;
  height: fit-content !important;
}

.product-item-right-left {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

@media screen and (max-width: $mobileBreakpoint) {
  .state {
    font-size: 0.8rem;
    text-wrap: unset;
  }

  .product-item-right-left {
    margin-top: 0.5rem;
  }

  .action-button {
    margin-right: 0;
  }

  .product-item-right {
    flex-direction: column-reverse;
  }

  .image-miniature {
    height: 50px !important;
    width: 50px !important;
  }
}

@import "styles/variables.scss";

.logistic-section {
  width: 100%;
  position: relative;
}

.loading-section {
  padding: 1rem;
  width: 100%;
  border: 1px solid $lightGray;
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-details-button {
  all: unset;
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: underline;
}

.more-details-button.hover:hover,
.more-details-button.active:active {
  color: $brandAccent;
}

.product-list {
  list-style: none;
}

.required-action {
  position: absolute;
  top: 10px;
  right: -10px;
  color: $error;
}

@import "styles/variables.scss";

.delete-button {
  background-color: $error !important;
  color: white;
}

.item {
  display: flex;
  gap: 2px;
  font-size: 16px;
  align-items: center;
  max-width: 1500px;
  width: 85vw;

  &:nth-child(2n) {
    background-color: $shadeSecondary;
  }
}

.item-element {
  overflow: auto;
  text-align: center;
  flex: 1;
  padding: 10px;

  &:nth-child(1) {
    text-align: left;
  }
}

.tools {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.mylink {
  display: flex;
  align-items: center;
  &:hover {
    color: $hoverAccent;
  }
}

.icon {
  cursor: pointer;
  height: 24px;
  width: 24px;
  &:nth-child(2) {
    &:hover {
      color: $error;
    }
  }
}

@media screen and (max-width: 1700px) {
  .item {
    font-size: 14px;
  }
}

@media screen and (max-width: 1400px) {
  .item {
    margin-left: auto;
    margin-right: auto;
    width: 90vw;
  }
}

@media screen and (max-width: 1235px) {
  .item {
    flex-direction: column;
    align-items: flex-start;

    .tools {
      width: 100%;
      padding-top: 20px;
      .icon {
        height: 30px;
        width: 30px;
      }
    }
  }
}

// .alphabet-list {
//   list-style: none;
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
//   margin-top: 1rem;
// }

// .alphabet-item {
//   cursor: pointer;
//   user-select: none;
// }

// .brand-modal {
//   padding: 1rem;
//   height: 100%;
// }

// .brand-list {
//   list-style: none;
//   overflow: auto;
// }

// .brand-item {
//   padding: 1rem;
//   cursor: pointer;
// }

// .description-input {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   gap: 0.5rem;
// }

// .input {
//   min-width: unset !important;
// }

// .input-container {
//   position: relative;
//   align-items: center;
//   display: flex;
// }

@import "/src/styles/variables.scss";

.brand-modal {
  position: relative;
  width: 100%;
  //   max-width: 400px;
  //   background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  //   max-height: 80vh;
}

.input {
  margin-bottom: 12px;
}

.alphabet-list {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 8px;
}

.alphabet-item {
  font-size: 1.2rem;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  //   color: #007bff;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.selected {
  color: $brandAccent;
  text-decoration: underline;
}

.alphabet-item:hover {
  text-decoration: underline;
}

.brand-list {
  list-style: none;
  overflow-y: auto;
  padding-right: 1rem;
}

.brand-item {
  padding: 1rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.brand-item:hover {
  background: #f7f7f7;
}

@import "styles/variables.scss";

.read-container {
  // margin-top: 20px;
  display: flex;
  justify-content: center;
  // border-radius: 5px;
  background-color: $brandSecondary;
  // padding: 100px;
}

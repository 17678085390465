.product-list-bottom {
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    width: 80px;
  }
}

.check-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.input-checkbox {
  order: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: unset;
  min-height: unset;
  height: 20px !important;
  width: 20px !important;
}

.input-text {
  min-height: unset;
  height: 40px !important;
  width: 120px !important;
}

@import "styles/variables.scss";

.error-icon-container {
  overflow: visible;
  width: 0px;
}

.error-icon {
  position: relative;
  z-index: 10;
  right: 50px;
  cursor: help;
  height: 50px;
  width: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.error-icon-responsive {
  right: 0px !important;
  top: -50px !important;
}

.error-icon-container-responsive {
  justify-content: flex-end;
}

.responsive-mobile {
  display: none;
}

.icon {
  color: $error;
}

.error-list-dropdown {
  background-color: $brandSecondary;
  border-radius: 4px 4px 0px 0px;
  box-shadow: $primaryShadow;
  width: fit-content;
  height: fit-content;
  padding: 1rem;
}

@media screen and (max-width: $mobileBreakpoint) {
  .responsive {
    display: none;
  }
  .responsive-mobile {
    display: flex;
    width: 100% !important;
    height: 80px !important;
  }
}

@import "styles/variables.scss";

.cart-to-logistic-container {
  width: 100%;
  margin: 1rem 0;
  padding: 0 2rem;
}

@media screen and (max-width: $laptopBreakpoint) {
  .cart-to-logistic-container {
    padding: 0 2rem;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .cart-to-logistic-container {
    padding: 0 1rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .cart-to-logistic-container {
    padding: 0 0.5rem;
    margin-top: 1.5rem;
  }
}

@media screen and (min-width: $laptopBreakpoint) {
  .cart-to-logistic-container {
    max-width: $laptopBreakpoint;
    margin-left: auto;
    margin-right: auto;
  }
}

@import "styles/variables.scss";

.overview-button {
  margin-left: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: flex-start;
}

.icon {
  width: 20px !important;
  height: 20px !important;
}

@import "styles/variables.scss";

.loading-container {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.shipping-list {
  padding-bottom: 20px;
}

.shipping-pro-history-modal {
  -webkit-box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  border-radius: 4px;
  box-shadow: $primaryShadow;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  max-height: 800px;
  max-width: 1000px;
  overflow: auto;
  width: 90vw;
  padding: 20px;
}

.shipping-pro-history-modal-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  //   justify-content: space-between;
  // padding: 20px;
}

.title {
  font-size: 30px;
  margin-bottom: 30px;
}

.no-option-found {
  font-size: 14px;
  font-style: italic;
  text-align: center;
}

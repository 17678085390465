@import "styles/variables.scss";

.delete-button {
  background-color: $error;
  color: white;
}

.icon {
  width: 50px !important;
  height: 50px !important;
}

.contact-form {
  width: 100%;
}

.logistic {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 1rem;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

.title {
  font-size: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-container {
  display: flex;
}

.buttons {
  display: flex;
  gap: 20px;

  button {
    padding: 5px;
  }
}

.addButton,
.closeIcon {
  cursor: pointer;
  font-size: 30px;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px black;

  margin-left: auto;
  margin-right: auto;
  &:hover {
    background-color: lightgray;
  }
}

.closeIcon {
  font-size: 15px;
  font-weight: bold;
  width: 25px;
  height: 25px;
  margin: unset;
}

.card {
  display: flex;
  gap: 10px;
  width: max-content;
  align-items: center;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: $brandAccent;
  background-color: $brandPrimary;
  margin-left: auto;
  margin-right: auto;
  min-width: 200px;
  height: 35px;
  width: fit-content;
  cursor: pointer;
}

.cross-selling-products-container {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.cross-selling-products-list {
  margin-top: 30px;
}

.cross-selling-products-item {
  display: flex;
  width: 500px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .description {
    flex: 4;
    display: flex;
  }

  .cart-quantity,
  .delete {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .delete-button {
    width: 50px;
    cursor: pointer;
    border: none;
    background-color: unset;

    &:hover,
    &:active {
      color: $error;
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.separator {
  margin: 20px 0px !important;
}

.input-line {
  display: flex;
  gap: 5px;
}

.comment {
  width: 100%;
  min-height: 100px;
  padding: 5px;
}

.comment-form {
  display: flex;
  justify-content: space-between;
}

.validate-button {
  background-color: $success;
  color: white;
}

@media screen and (max-width: 1400px) {
  .logistic {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1100px) {
  .logistic {
    margin: 0px;
    padding-inline: 10px;
  }
}

@media screen and (max-width: 960px) {
  .logistic {
    margin-top: 30px;
  }
}

@media screen and (max-width: 600px) {
  .title {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .comment-form {
    flex-direction: column;
  }
}

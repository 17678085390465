.slider-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  // max-width: 1008px;
  overflow-y: auto;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
}

.slider-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  flex: 1;
}

@import "styles/variables.scss";

.modal-container {
  position: fixed;

  z-index: 2001;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  max-width: 1000px;
  max-height: 800px;
  height: 98%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.exit-icon {
  position: absolute;
  z-index: 11;
  top: 10px;
  right: 10px;
  background-color: $brandAccent;
  height: 45px;
  width: 45px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  .icon {
    height: 30px;
    width: 30px;
  }
  &:active {
    background-color: $brandPrimary;

    .icon {
      color: $brandAccent;
    }
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.3;
  background-color: gray;
  height: 100%;
  width: 100%;
}

.validate-button {
  background-color: $error;
  color: white;
}

@media screen and (max-width: $mobileBreakpoint) {
  .modal {
    top: 0px;
    left: 0px;
  }
}

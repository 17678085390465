@import "styles/variables.scss";

.autoCompleteInput {
  width: 100%;
  height: 76px;
}

.itemList {
  max-height: 250px;
  width: 100%;
  background-color: white;
  z-index: 3;
  outline: 2px solid $brandAccent;
  border-top: none;
  position: relative;
  top: -2px;
  border-radius: 0 0 5px 5px;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
}

.item {
  padding: 5px 10px 5px 10px;
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: $brandAccent;
  }
}

.addNew {
  font-style: italic;

  font-size: 12px;
  opacity: 0, 7;
}

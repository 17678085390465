@import "styles/variables.scss";

.brand {
  margin-left: 20px;
  flex: 1;
}

.due-date {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-size: 14px;
  line-height: 18px;
}

.operations-acronym-list {
  display: flex;
  margin-left: 20px;
  flex: 2;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.operations-acronym {
  border: 2px solid black;
  font-weight: 500;
  border-radius: 4px;
  padding: 5px 10px;
  line-height: 14px;
  min-width: 47px;
  display: flex;
  justify-content: center;
}

.product-card {
  box-shadow: $primaryShadow;
  -webkit-box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border: 4px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:active {
    filter: brightness(90%);
  }
}

.product-card-left {
  display: flex;
  align-items: center;
  flex: 1;
}

.workshop-id {
  min-width: 155px;
}

@media screen and (max-width: $mobileBreakpoint) {
  .brand {
    display: none;
  }

  .workshop-id {
    min-width: 100px;
    font-size: 0.875rem !important;
    line-height: 1rem;
  }

  .operations-acronym-list {
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0;
  }
}

@import "styles/variables.scss";

.confirmation-modal {
  position: fixed;
  z-index: 2001;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  left: 27px;
  max-width: 1000px;
  position: relative;
  z-index: 10;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: fit-content;
  height: fit-content;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.3;
  background-color: gray;
  width: 100%;
  height: 100%;
}

.close-button {
  cursor: pointer;
  overflow: visible;
  position: relative;
  right: 24px;
  width: 0px;

  &:hover {
    color: $brandAccent;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .modal {
    // top: 27px;
    left: 0px;
  }
}

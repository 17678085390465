@import "styles/variables.scss";

.delete {
  &:hover {
    color: $error;
  }
}

.dots {
  flex: 1;
  height: 0px;
  border-bottom: 1px dashed black;
  margin: 0px 10px;
}

.product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.product-item-left {
  display: flex;
  cursor: pointer;
  &:hover {
    color: $brandAccent;
  }
}

.product-brand-group {
  margin-left: 10px;
}

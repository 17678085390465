@import "styles/variables.scss";

.confirm-modal-comp {
  align-items: center;
  background-color: $shadeSecondary;
  border-radius: 4px;
  -webkit-box-shadow: $primaryShadow;
  box-shadow: $primaryShadow;
  display: flex;
  min-height: 200px;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 30px;
  height: 100%;
  width: 100%;
}

.confirm-modal-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-section {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.message {
  text-align: center;
  font-size: 14px;
}

.validate-button {
  background-color: $success;
  color: white;
}

.title {
  font-weight: bold;
}

.confirm-modal-container {
  height: 100%;
  width: 100%;
}

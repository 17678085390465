@import "styles/variables.scss";

.beautiful-div {
  height: 5px;
  background-color: $brandAccent;
  border-radius: 0 0 3px 3px;
}

.more-tools {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  height: 35px;
  overflow: visible;
}

// .more-tools-container {
//   height: 35px;
//   overflow: visible;
// }

.more-tools-dropdown {
  background-color: $brandSecondary;
  border-radius: 4px 4px 0px 0px;
  box-shadow: $primaryShadow;
  width: fit-content;
  height: fit-content;
}

.more-tools-dropdown-wrapper {
  width: 0px;
  overflow: visible;
}

.more-button {
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  height: 35px;
  // width: 100%;
  &:active {
    color: $brandAccent;
  }
}


.option-list-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  line-height: 14px;
  padding: 10px;

  &:hover {
    background-color: $lightGray;
  }
}

.option-list-item-icon {
  width: 20px !important;
  margin-right: 10px;
}


.disabled {
  cursor: default;
  // background-color: gray;
  color: $darkGray;
  opacity: .8;
  pointer-events: none;

  &:hover {
    background-color: unset;
    color: $darkGray;
  }
}

@import "styles/variables.scss";

.read-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
}

.read-product-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
}

.left-top {
  display: flex;
}

.workshop-id,
.state {
  min-width: 190px !important;
}

.workshop-id {
  margin-right: 5px;
}

.main-picture {
  height: 400px;
  width: 500px;
  object-fit: cover;
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
}

.picture-container {
  display: flex;
  flex-direction: column;

  gap: 10px;
  align-items: flex-end;
}

.more-picture {
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $brandAccent;
  border-radius: 50px;

  &:hover {
    filter: brightness(80%);
  }
}

.left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  // margin-top: 30px;
  flex: 1;
}

.product-info,
.product-info-line-item {
  display: flex;
  flex-direction: column;
  font-size: 34px;

  span {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
  }
}

.product-info-line-item {
  flex: 1;
  height: 50px;
  justify-content: center;
  font-size: 24px;
  font-weight: 500;
}

.product-info-line {
  display: flex;
  width: 100%;
}

.product-operation-group {
  font-size: 24px;
  font-weight: bold;
}

.color,
.empty-color {
  height: 20px;
  width: 20px;
}

.product-color {
  display: flex;
  align-items: center;

  gap: 10px;
  font-size: 20px;
}

.empty-color {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid red;
  position: relative;
}

.diagonal-line {
  width: 0px;
  overflow: visible;
  width: 26.5px;
  position: relative;
  left: -34px;
  border-bottom: 1px solid red;
  transform: rotate(-45deg);
}

.operation-list-container {
  margin: 30px 0px;
  span {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }
  width: 500px;
  //   height: fit-content;
  font-size: 20px;
  overflow-wrap: break-word;
}

.operation-list {
  padding-left: 20px;
  height: 240px;
  overflow-y: auto;
  // scrollbar-color: rebeccapurple green;
  // scrollbar-width: 30px;

  li:not(:last-child) {
    margin-bottom: 10px;
  }
}

.history-list {
  span {
    font-size: 16px;
  }
}

.history-item {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.empty-history-message {
  font-weight: lighter;
  font-size: 12px;
  font-style: italic;
}

@media screen and (max-width: $laptopBreakpoint) {
  .read-product {
    padding: 1rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .read-product {
    padding: 0.5rem;
  }
  .read-product-container {
    flex-direction: column;
    align-items: center;
  }

  .left,
  .right {
    width: 100%;
    // padding-inline: 10px;
  }

  .operation-list-container,
  .main-picture {
    width: 100%;
  }

  .history-list {
    display: none;
  }
}

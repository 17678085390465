@import "styles/variables.scss";

.container,
.container-wide {
  position: fixed;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-wide {
  width: 100%;
}

.background,
.background-wide {
  background-color: $darkGray;
  position: fixed;
  z-index: 0;
  width: calc(100% - 300px);
  height: calc(100% - 50px);
  opacity: 0.4;
}

.background-wide {
  width: calc(100%);
  left: 55px;
}

.required-action {
  max-width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-spinner-container {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: $shadeSecondary;
  // min-width: 850px;
  overflow: auto;
  width: 100%;
  height: 100%;
  max-height: 860px;
  padding: 40px 35px 40px 35px;
  border-radius: 5px;
  // position: fixed;
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: $primaryShadow;
  -webkit-box-shadow: $primaryShadow;
}

.close-button {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 5px;
  right: 5px;

  &:hover {
    color: $brandAccent;
  }
}

.direction {
  font-size: 16px;
  width: fit-content;
  padding: 0px 5px;
  border-radius: 5px;
  color: $brandSecondary;
  font-weight: bold;
}

.address-comment {
  font-style: italic;
  font-size: 14px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.chronopost-tab-container {
  display: flex;
  gap: 4px;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
}

.chronopost-tab {
  cursor: pointer;
  padding: 4px 10px 4px 10px;
  // border: 1px solid black;
  border-bottom: 0px;
  border-radius: 5px 5px 0px 0px;
}

.chronopost-tab:hover,
.chronopost-tab-selected {
  font-weight: bold;
  background-color: $brandPrimary;
  color: $brandAccent;
  border-color: $brandAccent;
}

.empty-list-message {
  font-style: italic;
  text-align: center;
  font-size: 14px;
}

.outgoing {
  background-color: $info;
}

.incoming {
  background-color: $success;
}

.chronopost-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
}

.chronopost-list::-webkit-scrollbar {
  display: none;
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 10px;
}

.chronopost-item {
  display: flex;
  justify-content: space-between;
  //   gap: 10px;
}

.chronopost-item-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.left {
  flex: 1;
  //   align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.info-title {
  display: flex;
  justify-content: space-between;
  // align-items: center;
}

.info-title-left {
  font-weight: bold;
  display: flex;
  gap: 10px;
}

.shipping-info {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}

.addresse {
  display: flex;
  flex-direction: column;
}

.state {
  display: flex;
  justify-content: space-between;
  // justify-content: flex-end;
  gap: 10px;
  height: 50px;
}

.select-state {
  display: flex;
  align-items: center;
  gap: 50px;
  flex: 2;
}

.display-state {
  display: flex;
  align-items: center;
  gap: 50px;
}

.edit-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  // flex: 2;
  order: 2;

  &:hover {
    color: $brandAccent;
  }
}

.done-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  order: 2;
}

.done-button {
  cursor: pointer;
  background-color: $success;
  height: 30px;
  width: 30px;
  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &:hover {
    color: $brandPrimary;
  }
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.allday-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding-left: 40px;
  color: $error;
}

.external-id {
  border: 1px solid black;
  padding: 5px 10px;
}

.product-list {
  height: 160px;
  overflow: auto;
}

.product-list::-webkit-scrollbar {
  display: none;
}

.download-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.bottom {
  display: flex;

  justify-content: right;
}

.select-print-mode {
  width: 210px;
}

.access-button {
  display: flex;
  cursor: pointer;
}

.access-button-icon {
  height: 15px !important;
  width: 15px !important;
  color: $darkGray;
  &:hover {
    color: $brandAccent;
  }
}

// .access-button,
.download-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: $brandPrimary;
  color: $brandAccent;
  width: fit-content;
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  padding: 5px 10px;

  &:hover {
    color: $brandPrimary;
    background-color: $brandAccent;
  }
}

.required-action {
  flex-direction: column;
}

.modal-product-state-container {
  // height: 0;
  // overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 55px;

  &:hover,
  &:active {
    filter: brightness(80%);
  }
}

.modal-product-state {
  // border: 1px solid;
  border-radius: 4px 4px 0px 0px;
  width: fit-content;
  max-width: 100px;
  padding: 5px 5px;
  font-size: 9px;
  font-weight: bold;
  position: relative;
  line-height: 8px;
  top: 3px;
  // z-index: -1;
  text-align: center;
}

.workshop-id-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  max-width: 780px;
}

.workshop-id {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: white;
  background-color: #00458a;
  border-radius: 5px;
  padding: 0 6px;
  min-width: 100px;
  position: relative;
  z-index: 1;

  // &:hover {
  //   filter: brightness(120%);
  // }
}

.download-button {
  order: 2;
  // height: fit-content;
}

.cancel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  order: 3;
  cursor: pointer;
  &:active,
  &:hover {
    color: $error;
  }
}

hr {
  margin: 10px !important;
}

@media screen and (max-width: 1400px) {
  .container,
  .container-wide {
    width: 100%;
    left: 0;
  }
  .background,
  .background-wide {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: 900px) {
  .chronopost-item {
    flex-direction: column;
    // gap: 50px;
  }
  .allday-right {
    padding: 20px 0 0 0;
  }
  .modal {
    width: 95vw;
    min-width: unset;
  }
}

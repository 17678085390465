@import "styles/variables.scss";

.tools-container {
  display: flex;
  height: 0px;
  position: absolute;
  top: 0px;
  right: 0px;
  overflow: visible;
}

.delete-icon,
.zoom-icon {
  color: white;
  position: relative;
  bottom: 1px;
  &:hover {
    color: $error;
  }
}

.zoom-icon:hover {
  color: $brandPrimary;
}

.delete-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background-color: $brandAccent;
}

.image {
  object-fit: scale-down;
  height: 100%;
  width: 100%;
}

.image-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
}

.image-container:hover,
.image-container:active {
  filter: brightness(90%);
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@import "styles/variables.scss";

.drop-and-collect {
  display: flex;
}

.shipping-service-button {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 200px;
  height: 80px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-box-shadow: $primaryShadow;
  box-shadow: $primaryShadow;
  border: 1px solid $lightGray;
  font-weight: 900;

  &:hover {
    background-color: $shadeAccent;
    border-color: $brandAccent;

    color: $brandPrimary;
  }

  &:not(:last-child) {
    margin-right: 2rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .drop-and-collect {
    flex-direction: column;
  }

  .shipping-service-button {
    margin-right: 0 !important;
    margin-bottom: 1rem !important;
  }
}

@import "/src/styles/variables.scss";

.left,
.right {
  width: 100%;
}

.in-store-drop-off {
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  // gap: 1rem;
  max-width: 100%;
  margin-bottom: 1rem;
}

.in-store-drop-off-left {
  width: 100%;
  border-right: 1px solid $darkGray;
  padding-right: 0.5rem;
}

.in-store-drop-off-right {
  width: 100%;
  // border-left: 1px solid $darkGray;
  padding-left: 0.5rem;
}

.operation-form {
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  width: 100%;
}

.in-store-drop-off-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.in-store-drop-off-bottom {
  margin-top: auto;
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  border-top: 2px solid $darkGray;
  background-color: $lightGrayTransparent40;
}

.in-store-drop-off-bottom {
}

@media screen and (max-width: $tabletBreakpoint) {
  .in-store-drop-off {
    padding: 0.5rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .in-store-drop-off,
  .in-store-drop-off-bottom {
    grid-template-columns: 1fr;
  }
}

@import "styles/variables.scss";

.product-widget {
  cursor: pointer;
  height: 120px;
  width: 231px;
  -webkit-box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.12);
  background-image: linear-gradient(rgb(252, 252, 252), $shadeSecondary);
  border-radius: 3px;
  position: relative;
  border: 1px solid $darkGray;

  &:hover {
    border: 2px solid $brandAccent;
  }
}

.save-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.disabled {
  background-image: linear-gradient(rgb(143, 143, 143), #707070) !important;
  cursor: not-allowed !important;

  &:hover {
    border: 1px solid $darkGray !important;
  }
}

.save-icon {
  width: 50px !important;
  height: 50px !important;
  background-color: transparent;
}

.responsive-mobile {
  display: none;
}

@media screen and (max-width: $mobileBreakpoint) {
  .responsive {
    display: none;
  }
  .responsive-mobile {
    display: flex;
    width: 100% !important;
    height: 80px !important;
  }
}

@import "styles/variables.scss";

.read-cart-modal {
  -webkit-box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  border-radius: 4px;
  box-shadow: $primaryShadow;
  display: flex;
  height: 100%;
  flex-direction: column;
  max-height: 1200px;
  max-width: 1000px;
  overflow: auto;
  width: 95vw;
}

.read-cart-modal-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: 100%;
}

.read-cart-modal-body {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding-bottom: 8px;
}

.title {
  display: flex;
  width: 100%;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 10px;
}

.one-product {
  border-radius: 4px !important;
  right: 0px !important;
}

.product-list {
  width: fit-content;
  padding: 0px 0px 10px 3px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 10px);
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-width: $tabletBreakpoint) {
  .read-cart-modal {
    height: calc(95vh - 55px);
  }

  .title {
    font-size: 30px;
  }
}

@media (max-width: $mobileBreakpoint) {
  .read-cart-modal-body {
    flex-direction: column;
    height: fit-content;
  }
  .product-list {
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
    margin-bottom: 20px;
    height: fit-content;
    padding: 0px;
    margin: 0px;
    min-height: 73px;
  }
  .hidden {
    display: none;
  }
}

@import "styles/variables.scss";

.canceled {
  text-decoration: line-through;
  opacity: 0.5;
}

.dotted {
  flex: 1;
  height: 1px;
  border-bottom: 1px dotted $darkGray;
  margin: 0 10px;
}

.operation {
  display: flex;
  align-items: center;
  padding-left: calc(40px + 0.5rem);
  width: 100%;
}

.price,
.description {
  font-size: 0.875rem;
}

.refashion-section {
  margin-left: calc(40px + 1.5rem);
}

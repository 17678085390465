.discount-container {
  // padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.table-container {
  width: 100%;

  table {
    th {
      text-align: left;
    }
    th:nth-child(2) {
      text-align: right;
    }
    td:nth-child(2) {
      text-align: right;
    }
    width: 100%;
  }
}

.title {
  font-size: 1rem;
  font-weight: bold;
}

.not-found-message {
  font-style: italic;
  text-align: center;
  opacity: 0.5;
}

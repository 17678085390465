@import "styles/variables.scss";

.product-widget {
  cursor: pointer;
  height: 120px;
  width: 231px;
  border: none;
  -webkit-box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid $darkGray;
  background-color: $shadeSecondary;
  border-radius: 4px;
  margin-right: 20px;
  position: relative;

  &:hover {
    border: 2px solid $brandAccent;
  }
}

.product-widget.disabled {
  background-image: linear-gradient(rgb(143, 143, 143), #707070);
  cursor: not-allowed;
}

.add-icon {
  width: 50px !important;
  height: 50px !important;
  background-color: transparent;
}

.add-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  // opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

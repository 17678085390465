@import "styles/variables.scss";

.product-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  align-items: center;
  background-color: $shadeSecondary;
  box-shadow: $primaryShadow;
  -webkit-box-shadow: $primaryShadow;
  padding: 10px;
  width: 100%;
  border-radius: 4px;

  &:hover,
  &:active {
    border: 2px solid $brandAccent;
    padding: 8px;
    label {
      cursor: pointer !important;
      color: $brandAccent !important;
    }
  }

  margin-bottom: 10px;
}

.product-information {
  display: flex;
  width: 100%;
  justify-content: space-between;
  //   flex-direction: column;
}

.product-card-right {
  display: flex;
  height: 100%;
  flex-direction: column;
  //   justify-content: space-between;
  width: 100%;
}

.product-card-right-bottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.workshop-id,
.state {
  min-width: 190px !important;
}

.workshop-id {
  margin-bottom: 10px;
}

.brand {
  font-size: 18px;
}

.contact-container,
.brand-container,
.product-group-container {
  display: flex;
  flex-direction: column;
}

.product-group-container {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 20px;
}
.brand {
  font-size: 30px;
}

.contact {
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
}

.contact-container {
  margin-left: 5px;
}

@media screen and (max-width: $tabletBreakpoint) {
}
@media screen and (max-width: $mobileBreakpoint) {
  .product-card {
    grid-template-columns: 1fr;
  }

  .product-card-left {
    display: flex;
    justify-content: space-between;
  }

  .workshop-id,
  .state {
    min-width: 150px !important;
  }

  .state > div {
    min-width: 150px !important;
    min-height: 48px;
    line-height: 18px !important;
  }

  .workshop-id {
    margin: 0 10px 0 0;
  }
}

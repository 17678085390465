@import "styles/variables.scss";

.container,
.container-wide {
  display: flex;
  align-items: center;
  justify-content: center;
  // opacity: 0.4;
}

.container-wide {
  width: calc(100%);
  left: 55px;
}

.background,
.background-wide {
  background-color: $darkGray;
  position: fixed;
  z-index: 0;
  width: calc(100% - 300px);
  height: calc(100% - 50px);
  opacity: 0.4;
}

.background-wide {
  width: calc(100%);
  left: 55px;
}

.modal {
  background-color: $brandSecondary;
  // min-width: 550px;
  height: 100%;
  width: 100%;
  // max-height: 720px;
  padding: 40px 35px 20px 35px;
  border-radius: 5px;
  // position: fixed;
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: $primaryShadow;
  -webkit-box-shadow: $primaryShadow;
  overflow-y: scroll;
}

.close-button {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 5px;
  right: 5px;

  &:hover {
    color: $brandAccent;
  }
}

.nom-complet {
  display: flex;
  gap: 10px;
  font-weight: bold;
  font-size: 24px;
}

.access-button {
  display: flex;
  cursor: pointer;
}

.access-button-icon {
  height: 15px !important;
  width: 15px !important;
  color: $darkGray;
  &:hover {
    color: $brandAccent;
  }
}

.workshop-id-list {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  max-width: 480px;
}

.workshop-id {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: white;
  background-color: #00458a;
  border-radius: 5px;
  padding: 0 6px;
  min-width: 100px;

  // &:hover {
  //   filter: brightness(120%);
  // }
}

.state {
  display: flex;
  justify-content: space-between;
  // justify-content: flex-end;
  gap: 10px;
  height: 50px;

  select {
    width: 90%;
  }
}

.select-state {
  display: flex;
  align-items: center;
  gap: 50px;
}

.modal-product-state-container {
  // height: 0;
  // overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 55px;

  &:active {
    filter: brightness(80%);
  }
}

.modal-product-state {
  // border: 1px solid;
  border-radius: 4px 4px 0px 0px;
  width: fit-content;
  max-width: 100px;
  padding: 5px 5px;
  font-size: 9px;
  font-weight: bold;
  position: relative;
  line-height: 8px;
  top: 3px;
  z-index: -1;
  text-align: center;
}

.yellow,
.green,
.red {
  font-weight: bold;
}

.yellow {
  color: $brandAccent;
}

.green {
  color: $success;
}

.red {
  color: $error;
}

.edit-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  // flex: 2;
  order: 2;

  &:hover {
    color: $brandAccent;
  }
}

.modal-top {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}

.time-range {
  font-weight: bold;
  font-size: clamp(1.5rem, 2.5vw, 2rem);
}

.direction-container {
  // flex: 4;
}

.direction {
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  color: $brandSecondary;
  font-weight: bold;
}

.address {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address-icon {
  display: flex;
  align-items: center;
  // flex: 2;
}

.address-left {
  // flex: 3;
}

.outgoing {
  background-color: $info;
}

.incoming {
  background-color: $success;
}

.image-list {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  width: 100%;
  height: fit-content;
  // margin-top: 40px;
}

.product-list {
  height: 210px;
  // overflow: auto;
}

.product-list::-webkit-scrollbar {
  display: none;
}

.image-preview {
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;

  &:hover {
    filter: brightness(95%);
  }
}

.done-button {
  background-color: $success;
  height: 30px;
  width: 30px;
  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &:hover {
    color: $brandPrimary;
  }
}

.bottom {
  display: flex;
  justify-content: right;
}

.address-comment {
  font-style: italic;
  font-size: 14px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

hr {
  margin: 0;
}

.antsroute-logo {
  height: 1em;
  width: 1em;
  margin-right: 1em;
}

@media screen and (max-width: 1400px) {
  .container,
  .container-wide {
    width: 100%;
    left: 0;
  }
  .background,
  .background-wide {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .modal {
    height: 100%;
    max-height: unset;
  }
}

@media screen and (max-width: 760px) {
  .modal {
    width: 95vw;
    min-width: unset;
  }
}

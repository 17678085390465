@import "styles/variables.scss";

.search-component {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.title {
  padding: 1rem;
}

.search-component-body {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // margin-top: 40px;
  padding: 0 1rem;
  width: 100%;
}

.search-by-workshop-id-input {
  position: sticky;
  height: fit-content;
  top: 80px;
}

@media screen and (max-width: $laptopBreakpoint) {
  .search-component {
    // padding: 1rem;
  }

  .search-by-workshop-id-input {
    top: 10px;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .search-component-body {
    // flex-direction: column-reverse;
    padding: 0 0.5rem;
    top: 80px;
  }

  .search-by-workshop-id-input {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .search-component {
    // padding: 0 10px;
  }

  .title {
    padding: 0.5rem;
  }

  .search-component-body {
    padding: 0 0.5rem;
  }

  .search-by-workshop-id-input {
    top: 80px;
  }
}

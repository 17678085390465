@import "/src/styles/variables.scss";

.add-new-product {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $brandPrimary;
  padding: 0.5rem 1rem;
  height: 54px;
  // width: 133px;
}

.product-list {
  display: flex;
  margin-bottom: 0.2rem;
  padding-bottom: 0.5rem;
  //   overflow-y: auto;
}

.product {
  display: flex;
  align-items: center;
  border: 1px solid $brandPrimary;
  border-radius: 3px;
  padding: 0.5rem;
  width: 151px;
  cursor: pointer;
  margin-left: 0.5rem;
  position: relative;
}

.product-group-icon {
  width: 30px;
  margin-left: 0.5rem;
}

.selected {
  border: 2px solid $brandAccent;
  padding: calc(0.5rem - 1px);
  .delete {
    top: -11px;
    right: -11px;
  }
}

.delete {
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
}

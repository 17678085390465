@import "styles/variables.scss";

.in-store-drop-off {
  width: 100%;
  padding: 1rem;
}

.in-store-drop-off-header {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 1rem;
}

.responsive-mobile {
  display: none;
}

.estimate-button {
  position: absolute;
  width: 40px;
  height: 40px;
  top: -12px;
  left: -12px;
  z-index: 5;
  box-shadow: $primaryShadow;
  -webkit-box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  border: 1px solid #767789;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: $tabletBreakpoint) {
}

@media screen and (max-width: $mobileBreakpoint) {
  .in-store-drop-off {
    padding: 0.5rem;
    margin: 1rem 0;
  }
  .responsive {
    display: none;
  }
  .responsive-mobile {
    display: flex;
    width: 100% !important;
    height: 80px !important;
  }
  .estimate-button {
    left: -6px;
    top: -18px;
  }
}

@media screen and (min-width: $laptopBreakpoint) {
  .in-store-drop-off {
    max-width: $laptopBreakpoint;
    margin-left: auto;
    margin-right: auto;
  }
}

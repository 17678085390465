@import "/src/styles/variables.scss";

.action-container {
  //   margin-top: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
  background-color: $shadeSecondary;
  border-top: 2px solid $brandAccent;
}

.action-container-left,
.action-container-right {
  display: flex;
}

.action-button {
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.contact-information {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.contact-item {
  margin-right: 0.5rem;
  font-size: 0.8rem;
  line-height: 0.9rem;
}

.edit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px !important;
  height: 25px !important;
  font-size: 0.8rem;
}

.icon {
  height: 18px !important;
  width: 18px !important;
}

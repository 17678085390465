@import "styles/variables.scss";

.checkin-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  position: relative;
}

.contact-widget {
  padding: 2rem;
  border: 1px solid $darkGray;
  background-color: $shadeSecondary;
  border-radius: 4px;
  margin-right: 1rem;
  width: 100%;
}

.responsive-mobile {
  display: none !important;
}

.estimate-button {
  position: absolute;
  width: 40px;
  height: 40px;
  top: -12px;
  left: -12px;
  z-index: 5;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.33);
  -webkit-box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.33);
  background-color: $shadeSecondary;
  border: 1px solid #767789;
  border-radius: 5px;
}

@media screen and (max-width: $tabletBreakpoint) {
  .contact-widget {
    padding: 1rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .contact-widget {
    margin-right: 0;
  }
  .responsive {
    display: none !important;
  }
  .responsive-mobile {
    display: flex !important;
    width: 100% !important;
    height: 80px !important;
  }
  .estimate-button {
    left: -6px;
    top: -18px;
  }
}

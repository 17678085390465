@import "styles/variables.scss";

.color-picker {
  margin-bottom: 1rem;
}

.images-form-container {
  flex-wrap: wrap;
  width: 100%;
  padding-top: 12px;
}

.input-text {
  min-height: unset;
  height: 40px !important;
  width: 120px !important;
}

.validation-error {
  color: $error;
  border-color: $error;
}

.logo {
  width: 50px;
}

.product-group-select {
  display: flex;
}

.input-container {
  height: 76px;
}

@import "styles/variables.scss";

.modal-container {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-container-children {
  overflow-y: auto;
  position: relative;
  z-index: 2000;
  width: 100dvw;
  max-width: 1000px;
  height: 98dvh;
  max-height: 900px;
  background-color: $brandSecondary;
  border-radius: 5px;
  -webkit-box-shadow: $primaryShadow;
  box-shadow: $primaryShadow;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1999;
  background-color: #00000030;
}

.close-button-container {
  height: 0;
  width: 100%;
  max-width: 1000px;
  overflow: visible;
  position: relative;
  z-index: 2001;
  display: flex;
  justify-content: flex-end;
}

.close-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}

.wide-width {
  max-width: 1400px;
}

.normal-width {
  max-width: 1000px;
}

.narrow-width {
  max-width: 600px;
}

.fit-height {
  height: fit-content;
}

.normal-height {
  height: 98dvh;
}

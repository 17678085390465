@import "styles/variables.scss";

.quote-edit-container {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2rem;
  margin-bottom: 0.5rem;
}

.widgets-container {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 1rem;
}

.widget-container {
  background-color: $shadeSecondary;
  border: 1px solid $darkGray;
  border-radius: 3px;
  width: 100%;
  padding: 2rem;
  margin-right: 1rem;
}

.main-title {
  width: 100%;
  text-align: left;
  font-size: 30px;
  padding: 1rem 0px;
}

.right-widgets {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 1rem;
  width: 100%;
}

.right-widget-container {
  background-color: $shadeSecondary;
  border: 1px solid $darkGray;
  border-radius: 3px;
  padding: 2rem;
  width: 100%;
  // min-width: 375px;
}

.bottom-widget {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.total-price-widget {
  min-height: unset;
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: 0px;
  border: 2px solid $brandAccent;
}

.total-price-widget-container {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  font-weight: bold;
}

.send-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.send-button {
  cursor: pointer;
  height: 68px;
  width: 100px;

  border: none;
  border-radius: 5px;
  background-color: $brandPrimary;
  color: $brandAccent;

  &:hover {
    background-color: $brandAccent;
    color: $brandPrimary;
  }
}

@media screen and (max-width: $laptopBreakpoint) {
  .section-title {
    padding: 0px 10px;
  }

  .quote-edit-container {
    margin-top: 1rem;
  }

  .main-title {
    padding-top: 0;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .quote-edit-container {
    padding: 0 1rem;
  }

  .widget-container,
  .right-widget-container {
    padding: 1rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .widgets-container {
    display: flex;
    flex-direction: column;
  }

  .quote-edit-container {
    padding: 0 0.5rem;
  }
}

@import "styles/variables.scss";

.delete-icon-container {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.product-widget {
  cursor: pointer;
  height: 120px;
  width: 231px;
  border: 1px solid $darkGray;
  background-color: $shadeSecondary;
  border-radius: 3px;
  margin-right: 1rem;
  position: relative;
  padding: 1rem;
  opacity: 0.5;

  &:hover {
    border: 2px solid $brandAccent;
    padding: calc(1rem - 1px);

    .delete-icon-container {
      right: -1px;
      top: -1px;
    }
  }
}

.product-widget-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.product-widget.selected {
  opacity: 1;
  border: 2px solid #c5a37b;
  padding: calc(1rem - 1px);
  .delete-icon-container {
    right: -1px;
    top: -1px;
  }
}
.product-widget.error {
  border: 2px solid $error;
}
.product-widget.valid {
  border: 2px solid $success;
}
.product-widget.disabled {
  background-image: linear-gradient(rgb(143, 143, 143), #707070);
  cursor: not-allowed;
}

.product-info {
  width: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-info-brand {
  font-size: 14px;
  line-height: 14px;
  height: 40px;
  overflow: hidden;
}

.workshop-id-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: fit-content;
  background-color: $info;
  color: $brandSecondary;
  padding: 5px 5px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

.widget-preview {
  border-radius: 50%;
  display: block;
  min-height: 80px;
  width: 80px;
  height: 80px;
  object-fit: contain;
  background-color: white;
}

@import "styles/variables.scss";

.product-section {
  flex: 1;
  min-height: 620px;
  padding: 2rem;
  border: 1px solid $darkGray;
  background-color: $shadeSecondary;
  border-radius: 3px;
  height: fit-content;
}

@media screen and (max-width: $tabletBreakpoint) {
  .product-section {
    padding: 1rem;
  }
}

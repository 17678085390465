@import "styles/variables.scss";

.create {
  display: flex;
  flex-direction: column;
  // margin-top: 40px;
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.contact-form {
  width: 100% !important;
}

.title {
  min-height: 68px;
  font-size: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 10px;
  background-color: $brandAccent;
  border: none;
  border-radius: 5px;
  color: $brandPrimary;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: $brandAccent;
    background-color: $brandPrimary;
  }
}

.shop-form {
  display: flex;
  //background-color: blueviolet;
}

.add-user {
  // order: 4;
  margin-top: 40px;
}

.already-exist {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 250px;
}

.alert {
  color: red;
  //margin-right: 10px;
}

.error {
  font-style: italic;
  font-size: 12px;
  color: $error;
}

.shop-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.delete-button {
  background-color: $error;
  color: white;
}

.add-shop-button {
  max-width: 164px;
  margin-top: 10px;
}

.separator {
  margin: 20px 0px 20px 0px !important;
}

@media screen and (max-width: $tabletBreakpoint) {
  .create {
    padding: 0px 10px;
  }
}

@import "/src/styles/variables.scss";

.contact-modal {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contact-form {
  margin-bottom: 1rem;
}

@import "styles/variables.scss";

.pro-express-checkin-modal {
  -webkit-box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  border-radius: 4px;
  box-shadow: $primaryShadow;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  max-height: 800px;
  max-width: 1000px;
  overflow: auto;
  width: 90vw;
  padding: 20px;
}

.pro-express-checkin-modal-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.pro-express-checkin-modal-container-top {
  height: 100%;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.section-title {
  font-size: 24px;
  font-weight: 300;
}

.validate-button {
  background-color: $brandAccent;
  border-radius: 3px;
  border: none;
  box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  height: 36px;
  min-width: 164px;
  padding: 10px;

  &:hover {
    background-color: $brandPrimary;
    color: $brandAccent;
  }
}

@import "src/styles/variables.scss";

.state-button {
  display: flex;
  align-items: center;
  border: unset;
  width: fit-content !important;
  text-wrap: nowrap;
  height: 34px;
  border: 1px solid $darkGray;
  border-radius: 3px;
}

.icon {
  height: 15px !important;
  width: fit-content !important;
  padding-left: 0.3rem;
}

.dropdown-container {
  height: 0px;
  width: 0px;
  overflow: visible;
}

.select-state {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.select-state-container {
  height: 100%;
}

.dropdown {
  list-style: none;
}

.dropdown-item {
  cursor: pointer;
  padding: 0.6rem 1rem;
  font-size: 0.875rem;
  line-height: 1rem;

  &:hover {
    background-color: $shadeAccent;
  }
}

.selected {
  background-color: $shadeAccent;
}

.state-select-dropdown {
  background-color: $brandSecondary;
  border-radius: 4px 4px 3px 3px;
  border: 1px solid $lightGray;
  box-shadow: $primaryShadow;
  width: fit-content;
  height: fit-content;
  max-height: 180px;
  overflow: auto;
}

.discount-logo {
  height: 1.5em;
  margin-right: 0.5em;
}

.dropdown-item {
  cursor: pointer;
  padding: 0.6rem 1rem;
  font-size: 0.875rem;
  line-height: 1rem;

  &:hover {
    background-color: $shadeAccent;
  }
}

.selected {
  background-color: $shadeAccent;
}

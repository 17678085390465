@import "styles/variables.scss";

.section-title {
  height: 0px;
  overflow: visible;
  position: relative;
  z-index: 0;
}

.title {
  text-align: center;
  margin-top: 10px;
}

.validated-section {
  display: flex;
  justify-content: center;
  border: 3px dashed $brandAccent;
  border-radius: 4px;
  background-color: white;

  width: 100%;
  // aspect-ratio: 1.2;

  // max-width: 250px;
}

.validated-section-container {
  width: 100%;
  overflow: hidden;
}

.validated-product-list {
  width: 100%;
  height: 100%;
  // height: 50vh;
  overflow: auto;
  position: relative;
  z-index: 1;
}

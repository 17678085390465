@import "styles/variables.scss";

.logistic-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.products-list {
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 0;
  width: 100%;
}

.show-more {
  cursor: pointer;
  font-style: italic;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  &:hover {
    text-decoration: underline;
    color: $brandAccent;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .products-list {
    padding: 0 1rem 0 0;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .products-list {
    padding: 0;
  }
}

@import "styles/variables.scss";

.logistic-action-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
  width: 100%;
}

.logistic-action-container-bottom {
  display: flex;
  justify-content: flex-end;
}

.previous-button {
  margin-right: 20px;
}

@import "styles/variables.scss";

.loading-spinner-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
}

.set-date-container {
  align-items: center;
  display: flex;
  gap: 20px;
  width: fit-content;
}

.reset-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  order: 2;
  width: 36px;

  &:hover {
    color: $brandAccent;
  }
}

.reset-button {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 36px;
  margin-left: 20px;
}

.in-store-collect {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1500px;
  padding: 0 1rem;
  margin-left: auto;
  margin-right: auto;
}

.input-container {
  width: fit-content;
  margin-bottom: 1rem;
}

.title {
  width: 100%;
}
